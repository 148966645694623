<template>
  <div>
    <div class="col-12 animated fadeIn">
      <div class="row">
        <div class="col-lg-8 col-md-12 order-lg-1 order-md-2">
          <div class="card sticky-top">
            <!--            <div class="loader loader-backdrop loader-backdrop-portaluseraccounts" v-if="isUserAccountsLoading"></div>-->
            <div v-if="isUserAccountsLoading"><Loader-spinner /></div>
            <div class="card-header">
              <i class="fa fa-wrench mr-0"></i>
              {{ $t('portalPreview.params') }}
            </div>
<!--            <div class="card-block">-->
<!--              <div class="col-12">-->
<!--                &lt;!&ndash; <input type="text" v-validate="'url'" v-model="baseUrl" /> &ndash;&gt;-->
<!--                <div class="row">-->
<!--                  <div class="col-12">-->
<!--                    <div class="input-group input-group-sm">-->

<!--                                    <span class="input-group-addon filters__field-icon">-->
<!--                  <i class="fa  fa-file-code-o"></i>-->
<!--                </span>-->

<!--                      <Select-component-v2-->
<!--                        show-delete-button-->
<!--                        small-size-->
<!--                        v-model="selectedPage"-->
<!--                        track-by="name"-->
<!--                        option-id-name="id"-->
<!--                        :async-function="requestPages.bind(this)"-->
<!--                        :placeholder="$t('portalStats.selectPage')"-->
<!--                      />-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="row mt-1">-->
<!--                  <div class="col-6">-->
<!--                                    <div class="input-group input-group-sm">-->

<!--                                    <span class="input-group-addon filters__field-icon">-->
<!--                  <i class="fa  fa-file-o"></i>-->
<!--                </span>-->
<!--                    <Select-component-v2-->
<!--                      show-delete-button-->
<!--                      small-size-->
<!--                      v-model="selectedProfile"-->
<!--                      track-by="name"-->
<!--                      option-id-name="id"-->
<!--                      :async-function="requestProfiles.bind(this)"-->
<!--                      :placeholder="$t('portalStats.selectProfile')"-->
<!--                    />-->
<!--                                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-6">-->
<!--                                                    <div class="input-group input-group-sm">-->

<!--                                    <span class="input-group-addon filters__field-icon">-->
<!--                  <i class="fa fa-user"></i>-->
<!--                </span>-->

<!--                    <Select-component-v2-->
<!--                      show-delete-button-->
<!--                      small-size-->
<!--                      v-model="selectedUser"-->
<!--                      track-by="identity"-->
<!--                      option-id-name="id"-->
<!--                      :async-function="requestAccounts.bind(this)"-->
<!--                      :placeholder="$t('portalStats.selectAccount')"-->
<!--                    />-->
<!--                                                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                &lt;!&ndash; <div class="row mt-1">-->
<!--                  <div class="col-6">-->
<!--                    <label :for="'portal-link'" class="">{{ $t('portalStats.selectTheme') }}</label>-->
<!--                    <Select-component-->
<!--                      v-model="selectedTheme"-->
<!--                      track-by="name"-->
<!--                      option-id-name="name"-->
<!--                      :options="themeTypes"-->
<!--                      :placeholder="$t('portalStats.selectTheme')"-->
<!--                    />-->
<!--                  </div>-->
<!--                </div> &ndash;&gt;-->
<!--                <div class="row mt-1">-->
<!--                  <div class="col-12 form-group">-->
<!--                    <label :for="'portal-link'" class="">{{ $t('portalPreview.portalLink') }}</label>-->
<!--                    <div class="d-flex">-->
<!--                      <textarea-->
<!--                        :name="'portal-link'"-->
<!--                        :id="'portal-link'"-->
<!--                        v-model="generatePortalLink"-->
<!--                        readonly-->
<!--                        row="3"-->
<!--                        class="form-control input"-->
<!--                      />-->
<!--                      <button-->
<!--                        class="btn btn-success ml-q"-->
<!--                        v-clipboard:copy="generatePortalLink"-->
<!--                        v-tooltip.top-center="{-->
<!--                          content: $t('general.copy')-->
<!--                        }"-->
<!--                      >-->
<!--                        <i class="fa fa-lg fa-clone"></i>-->
<!--                      </button>-->
<!--                      <button-->
<!--                        @click="redirectTo(generatePortalLink)"-->
<!--                        class="btn btn-primary ml-q"-->
<!--                        v-tooltip.top-center="{-->
<!--                          content: $t('userAccount.goToLink', { link: generatePortalLink })-->
<!--                        }"-->
<!--                      >-->
<!--                        <i class="fa fa-lg fa-link"></i>-->
<!--                      </button>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
            <div class="card-block">
              <div class="col-12">
                <!-- <input type="text" v-validate="'url'" v-model="baseUrl" /> -->
                <div class="row">
                  <div class="col-12">
                    <div class="input-group input-group-sm page-filter">
                      <span class="input-group-addon filters__field-icon">
                        <i class="fa  fa-file-code-o"></i>
                      </span>
                      <Select-component-v2
                        show-delete-button
                        small-size
                        v-model="selectedPage"
                        track-by="name"
                        option-id-name="id"
                        :async-function="requestPages.bind(this)"
                        :placeholder="$t('portalStats.selectPage')"
                      />
                    </div>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-6">
                        <div class="input-group input-group-sm profile-filter">
                          <span class="input-group-addon filters__field-icon">
                            <i class="fa  fa-file-o"></i>
                          </span>
                          <Select-component-v2
                            show-delete-button
                            small-size
                            v-model="selectedProfile"
                            track-by="name"
                            option-id-name="id"
                            :async-function="requestProfiles.bind(this)"
                            :placeholder="$t('portalStats.selectProfile')"
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="input-group input-group-sm account-filter">
                          <span class="input-group-addon filters__field-icon">
                            <i class="fa fa-user"></i>
                          </span>
                          <Select-component-v2
                            show-delete-button
                            small-size
                            v-model="selectedUser"
                            track-by="identity"
                            option-id-name="id"
                            :async-function="requestAccounts.bind(this)"
                            :placeholder="$t('portalStats.selectAccount')"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-1">
                  <div class="col-12 form-group">
                    <label :for="'portal-link'" class="">{{ $t('portalPreview.portalLink')
                      }}</label>
                    <div class="d-flex">
                      <textarea
                        :name="'portal-link'"
                        :id="'portal-link'"
                        v-model="generatePortalLink"
                        readonly
                        row="3"
                        class="form-control input"
                      />
                      <WButton success
                        customClass="ml-q"
                        v-clipboard:copy="generatePortalLink"
                        v-tooltip.top-center="{
                          content: $t('general.copy')
                        }"
                      >
                        <i class="fa fa-lg fa-clone"></i>
                      </WButton>
                      <WButton
                        customClass="ml-q"
                        @click="redirectTo(generatePortalLink)"
                        v-tooltip.top-center="{
                          content: $t('userAccount.goToLink', { link: generatePortalLink })
                        }"
                      >
                        <i class="fa fa-lg fa-link"></i>
                      </WButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 order-lg-2 order-md-1">
          <div class="card sticky-top">
            <!--            <div v-if="isPortalAdsLoading" class="loader loader-backdrop loader-backdrop-portalads" />-->
            <div v-if="isPortalAdsLoading"><Loader-spinner centered></Loader-spinner></div>
            <div class="card-header">
              <i class="fa fa-television mr-0" />
              {{ $t('portalPreview.preview') }}
            </div>
            <div class="card-block">
              <div class="ml-auto">
                <div class="form-group portal-iframe-container d-flex">
                  <!-- 667x375 is iPhone 6 -->
                  <iframe
                    v-if="selectedPage.id && selectedProfile.id && selectedUser.id"
                    id="preview-iframe"
                    class="m-auto"
                    width="375"
                    height="667"
                    sandbox="allow-same-origin allow-scripts"
                    seamless
                    :src="generatePortalLink"
                  ></iframe>
                  <div v-else class="ml-2">
                    {{ $t('portalPreview.fillData') }}:
                    <ol>
                      <li v-if="!selectedPage.id">
                        {{ $t('portalStats.selectPage') }}
                      </li>
                      <li v-if="!selectedProfile.id">
                        {{ $t('portalStats.selectProfile') }}
                      </li>
                      <li v-if="!selectedUser.id">
                        {{ $t('portalStats.selectAccount') }}
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import SelectComponent from '../../components/Universal/select/select-component.vue';
import SelectComponentV2 from '../../components/Universal/select/select-componentV2.vue';
import portalProfilesService from '../../services/portalProfilesService';
import portalUserAccountsService from '../../services/portalUserAccountsService';
import portalPagesService from '../../services/portalPagesService';

export default {
  name: 'PortalPreview',
  components: {
    // SelectComponent,
    SelectComponentV2
  },
  data() {
    return {
      isPortalAdsLoading: false,
      baseUrl: '',
      selectedPage: {},
      selectedProfile: {},
      selectedUser: {},
      selectedTheme: { name: this.$t('portalPreview.default'), value: 'default', id: 'def' },
      endpoint: '',
      queryParamsProfile: '',
      queryParamsUser: '',
      queryParamsTheme: ''
    };
  },
  // watch: {
  //   'selectedPage.url_id': function () {
  //     this.endpoint = `${this.selectedPage.url_id}/`;
  //   },
  //   'selectedProfile.id': function () {
  //     const { cpe, wlan, nas_id } = this.selectedProfile.condition;
  //     this.queryParamsProfile = `&cpe_id=${cpe[0] || ''}&wlan_id=${wlan[0] || ''}&nas_id=${nas_id[0] || ''}`;
  //   },
  //   'selectedUser.id': function () {
  //     const { macs } = this.selectedUser;
  //     const clientIp = '10.0.0.211';
  //     this.queryParamsUser = `?mac=${macs[0] || ''}&client_ip=${clientIp}`;
  //   },
  //   'selectedTheme.value': function () {
  //     const { value } = this.selectedTheme;
  //     this.queryParamsTheme = `&themeType=${value || ''}`;
  //   }
  // },
  watch: {
    'selectedPage.url_id': function () {
      try {
        this.endpoint = this.selectedPage.url_id ? `${this.selectedPage.url_id}/` : '';
      } catch (e) {
        this.endpoint = ''
      }

    },
    'selectedProfile.id': function () {
      try {
        const {cpe, wlan, nas_id} = this.selectedProfile.condition;
        this.queryParamsProfile = `&cpe_id=${cpe[0] || ''}&wlan_id=${wlan[0] || ''}&nas_id=${nas_id[0] || ''}`;
      } catch (e) {
        this.queryParamsProfile = ''
      }

    },
    'selectedUser.id': function () {
      try {
        const {macs} = this.selectedUser;
        const clientIp = '10.0.0.211';
        this.queryParamsUser = `?mac=${macs[0] || ''}&client_ip=${clientIp}`;
      } catch (e) {
        this.queryParamsUser = ''
      }


    },
    'selectedTheme.value': function () {
      try {
        const {value} = this.selectedTheme;
        this.queryParamsTheme = `&themeType=${value || ''}`;
      } catch (e) {
        this.queryParamsTheme = ''
      }

    }
  },
  methods: {
    async requestProfiles(searchQuery) {
      // return portalProfilesService.requestProfiles(searchQuery);
      return portalProfilesService.requestProfilesFilteredByLocationAndWithChilds(this, searchQuery);
    },
    async requestPages(searchQuery) {
      // return portalPagesService.requestPages(searchQuery);
      return portalPagesService.requestPagesFilteredByLocationAndWithChilds(this, searchQuery);
    },
    async requestAccounts(searchQuery) {
      // return portalUserAccountsService.requestAccounts(searchQuery);
      return portalUserAccountsService.requestAccountsFilteredByLocationAndWithChilds(this, searchQuery);
    },
    redirectTo(url) {
      window.open(url, '_blank', 'noopener noreferrer');
    }
  },

  computed: {
    portalBaseUrl() {
      return this.$store.getters.portalBaseUrl;
    },
    themeTypes() {
      return [
        { name: this.$t('portalPreview.default'), value: 'default', id: 'def' },
        { name: this.$t('portalPreview.minimalistic'), value: 'minimalistic', id: 'min' }
      ];
    },
    isUserAccountsLoading() {
      return this.$store.state.loadingPortalUserAccounts || this.$store.state.loadingPortalProfiles;
    },
    generatePortalLink() {
      const url = new URL(this.endpoint, this.baseUrl);
      return `${url}${this.queryParamsUser}${this.queryParamsProfile}${this.queryParamsTheme}`;
    }
  },
  created() {
    this.baseUrl = this.portalBaseUrl;
  }
};
</script>

<style lang="scss">
.loader-backdrop-portalads:after {
  position: absolute;
  top: 30px;
}
</style>

<style lang="scss" scoped>
.loader-all-page-loading:after {
  position: absolute;
  top: 40vh;
}

#preview-iframe {
  display: block;
  border: none;
}

  .page-filter{
    z-index: 101 !important;
  }
    .profile-filter{
        z-index: 100 !important;
  }
    .account-filter{
        z-index: 100!important;
  }
</style>

<!-- NOTE: icons from http://www.iconarchive.com/show/free-multimedia-icons-by-designbolts/Photo-icon.html -->
